import React from 'react'
import Header from "../components/Header"


const Lancamentos = () => {
  return (
    <>
      <Header />
      <div>Lancamentos</div>
    </>
    
  )
}

export default Lancamentos