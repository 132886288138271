import React from 'react'
import Header from "../components/Header"


const Films = () => {
  return (
    <>
      <Header />

      <div>Films</div>
    </>
  
  )
}

export default Films