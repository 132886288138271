import React from 'react'
import Header from "../components/Header"


const Series = () => {
  return (
    <>
      <Header/>
      <div>Series</div>
    </>
    
  )
}

export default Series